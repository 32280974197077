import React from 'react';
import { type LogoProps } from 'bb/common/Logo';

export type ChristmasLogoProps = LogoProps;

const ChristmasLogo: React.FC<ChristmasLogoProps> = ({
    alt = 'Bookbeat logo',
    variant,
    ...restProps
}) => {
    const logoSrc =
        variant === 'white'
            ? '/images/svg/christmas_logo_white.svg'
            : '/images/svg/christmas_logo_black.svg';

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={logoSrc} alt={alt} {...restProps} />;
};

export default ChristmasLogo;
